import ProgendaUtils from "./progenda_utils.js";
import { min, max, pluck } from "underscore";
import PI from "services/progenda_interface.js";
import CalendarUtilsReact from "shared/calendar_utils_react.js";

const allowedViewsHash = {
  "center": ["agendaDay", "agendaTwoDay", "agendaThreeDay", "agendaWeek", "withoutWeekends", "dayGridMonth"],
  "calendar": ["timeGridDay", "timeGridWeek", "withoutWeekends", "dayGridMonth"],
  "resource": ["timeGridDay", "timeGridWeek", "withoutWeekends"]
}

const CalendarUtils = {
  defaultView: function(FCComponent) {
    let allowedViews = allowedViewsHash[FCComponent];
    if (FCComponent === "center") {
      let view = ProgendaUtils.getStorageItem("centerView");
      return allowedViews.includes(view) ? view : "agendaTwoDay";
    }
    else if (FCComponent === "calendar") {
      let view = ProgendaUtils.getStorageItem("view");
      if (allowedViews.includes(view)) {
        return view;
      } else {
        return $(window).width() > 700 ? "timeGridWeek" : "withoutWeekends"
      }
    }
    else if (FCComponent === "resource") {
      let view = ProgendaUtils.getStorageItem("resourceView");
      return allowedViews.includes(view) ? view : "timeGridWeek";
    }
    else {
      return null;
    }
  },
  getSelectedCalendars: function(calendars) {
    var selectedCalendarsIds = ProgendaUtils.getStorageItem("defaultSelectedCalendars");
    if (selectedCalendarsIds !== null) {
      return calendars.filter(calendar => JSON.parse(selectedCalendarsIds).includes(calendar.id));
    } else {
      return [];
    }
  },
  setDefaultSelectedCalendars: function(selectedCalendars) {
    ProgendaUtils.setStorageItem("defaultSelectedCalendars", JSON.stringify(selectedCalendars));
  },

  getAvailablesFieldsPDF: function() {
    return {
      patientFields: [
        "birthdate",
        "phone_number",
        "email",
        "blacklist",
        "new_patient",
        "address",
        "title",
        "appointment_notes"
      ],
      appointmentFields: [
        "title",
        "service_name",
        "notes",
        "patient_notes",
        "notes_about_patient",
        "appointment_address",
        "at_home",
        "noshow"
      ]
    };
  },

  getSelectedFieldsPDF: function() {
    var fields = ProgendaUtils.getStorageItem("fieldsPDF");
    if (fields !== null) {
      return JSON.parse(fields);
    } else {
      return {
        patientFields: ["birthdate", "phone_number"],
        appointmentFields: [
          "title",
          "service_name",
          "notes",
          "patient_notes",
          "notes_about_patient"
        ],
        hideLayout: false
      };
    }
  },
  setSelectedFieldsPDF: function(fields) {
    ProgendaUtils.setStorageItem("fieldsPDF", JSON.stringify(fields));
  },

  getDisplayCanceledAppointments: function() {
    var display =
      ProgendaUtils.getStorageItem("displayCanceledAppointments") === "true";
    return display;
  },
  setDisplayCanceledAppointments: function(mode) {
    ProgendaUtils.setStorageItem("displayCanceledAppointments", mode);
  },
  getSelectedCalendarsFromURL: function(calendars) {
    var postcode = ProgendaUtils.getFromURL("postcode");
    if (postcode !== null) {
      return calendars.filter(calendar =>
        calendar.postcodes.join(" ").includes(postcode)
      );
    }
  },
  makeSwipable: function(fullCalendarComponent) {
    if (ProgendaUtils.isMobile()) {
      var datePickerWithInput = fullCalendarComponent.props.getDatePickerWithInput();
      fullCalendarComponent.calendarJqueryElement.swipe({
        allowPageScroll: "vertical",
        preventDefaultEvents: false,
        excludedElements: [],
        swipeLeft: function(event, direction, distance, duration, fingerCount) {
          if (event.type == "touchend" && $(".fc-helper").length === 0) {
            // do not swipe if selecting event
            fullCalendarComponent.isSwiping = true;
            datePickerWithInput.next();
            fullCalendarComponent.isSwiping = false;
          }
        },
        swipeRight: function(
          event,
          direction,
          distance,
          duration,
          fingerCount
        ) {
          if (event.type == "touchend" && $(".fc-helper").length === 0) {
            // do not swipe if selecting event
            fullCalendarComponent.isSwiping = true;
            datePickerWithInput.prev();
            fullCalendarComponent.isSwiping = false;
          }
        }
      });
    }
  },
  tooltipOnDate: function(
    element,
    event,
    alreadyFormatted = false,
    elementIsDragged = false
  ) {
    if (event.start === null) {return}
    var startFormated = ProgendaUtils.dateFormat(
      moment.tz(
        alreadyFormatted ? event.start : event.start.format(),
        event.tz
      ),
      "simple_time_format"
    );
    var stopFormated = ProgendaUtils.dateFormat(
      moment.tz(alreadyFormatted ? event.end : event.end.format(), event.tz),
      "simple_time_format"
    );
    var content = startFormated + " - " + stopFormated + "<br />" + event.title;
    var dateContainer = $(
      '<span class="icon-container custom-tooltip" data-toogle="tooltip" data-placement="bottom" data-trigger="hover" title="' +
        ProgendaUtils.filterXSS(content.replace(/"/g, "&#34;")) +
        '"></span>'
    );
    var start = "<span class='start-date'>" + startFormated + "</span>";
    var stop = "<span class='stop-date'> - " + stopFormated + "</span>";
    dateContainer.prepend(stop);
    dateContainer.prepend(start);
    element.find("div.fc-content .fc-time").prepend(dateContainer);
    if (!elementIsDragged) {
      // add the tooltip only if the element isn't dragged
      dateContainer.tooltip({ html: true, container: $(".fc-body") });
    }
  },
  tooltipOnHover: function(element, logo, text, elementIsDragged = false) {
    if (text !== undefined && text !== null && text !== "") {
      var elem = $(
        '<span class="icon-container custom-tooltip" data-toogle="tooltip" data-placement="bottom" data-trigger="hover" title="' +
          ProgendaUtils.filterXSS(text.replace(/"/g, "&#34;")) +
          '">' +
          '<i class="' +
          logo +
          '"></i>&nbsp;' +
          "</span>"
      );
      element.find("div.fc-content .fc-time").prepend(elem);
      if (!elementIsDragged) {
        elem.tooltip({ html: true, container: $(".fc-body") });
      }
    } else {
      element
        .find("div.fc-content .fc-time")
        .prepend('<i class="' + logo + '"></i>&nbsp;');
    }
  },
  patientArrivedAtListener: function(element, event) {
    const { extendedProps } = event;
    const patientNotArrived = extendedProps.patientArrivedAt === null;

    var elem = $(
      '<span class="icon-container"><i class="' +
        (patientNotArrived ? "clickable-fa " : "clicked-fa") +
        ' fa fa-thumbs-up"></i>&nbsp;</span>'
    );
    elem.on("click", e => {
      if (!extendedProps.patientArrivedAt) {
        element.find(".fa-thumbs-up").removeClass("clickable-fa");
        element.find(".fa-thumbs-up").addClass("clicked-fa");
      } else {
        element.find(".fa-thumbs-up").addClass("clickable-fa");
        element.find(".fa-thumbs-up").removeClass("clicked-fa");
      }

      let patientArrivedAt = null;
      patientArrivedAt = extendedProps.patientArrivedAt
        ? null
        : moment().unix();

      PI.patch({
        url: PI.adminCalendarAppointmentPath(extendedProps.calendarSlug, {
          id: event.id
        }),
        data: {
          appointment: {
            patientArrivedAt
          }
        }
      });
      e.preventDefault();
      e.stopPropagation();
    });
    element.find("div.fc-content .fc-time").prepend(elem);
  },
  patientCallListener: function(element, event) {
    var elem = $(
      '<span class="icon-container"><i class="fa fa-bell"></i>&nbsp;</span>'
    );
    elem.on("click", e => {
      PI.post({
        url: PI.adminCalendarAppointmentPatientCallsPath(
          event.extendedProps.calendarSlug,
          event.id
        )
      });
      e.preventDefault();
      e.stopPropagation();
    });
    element.find("div.fc-content .fc-time").prepend(elem);
  },
  copyAppointment: function(element, event) {
    const { extendedProps } = event;
    const isCopied = extendedProps.isCopied ;
    var elem = $(
        '<span class="icon-container"><i class="' + (isCopied ? "clicked-fa" : "clickable-fa")+ ' fa fa-copy"></i>&nbsp;</span>'
    );
    element.find("div.fc-content .fc-time").prepend(elem);
  },
  computeSlot: function(calendars) {
    if (Object.keys(calendars).length === 0) {
      return "00:60:00";
    }
    var start = this.getDisplayStart(calendars).get("minute");
    var averageTime = this.getAverageTime(calendars);
    if ((60 - start) % averageTime === 0 && 60 % averageTime === 0) {
      return "00:60:00";
    } else if (60 % start === 0 && 60 % averageTime === 0) {
      let startString = start.toString();
      if (startString.length === 1) {
        startString = `0${startString}`;
      }
      return "00:" + startString + ":00";
    } else {
      return "00:01:00";
    }
  },
  getDisplayStart: function(calendars, returnFormattedTime = false) {
    let startValue;
    if (calendars.length === 0) {
      startValue = moment()
        .startOf("day")
        .hours(8);
    } else {
      startValue = min(
        calendars.map(function(calendar) {
          return moment(calendar.displayStart).utc();
        })
      );
    }
    if (returnFormattedTime) {
      return startValue.format(CalendarUtilsReact.timeDateFormats.time);
    }
    return startValue;
  },
  getDisplayEnd: function(calendars, returnFormattedTime = false) {
    let endValue;
    if (calendars.length === 0) {
      endValue = moment()
        .startOf("day")
        .hours(19);
    } else {
      endValue = max(
        calendars.map(function(calendar) {
          return moment(calendar.displayEnd).utc();
        })
      );
    }
    if (returnFormattedTime) {
      return endValue.format(CalendarUtilsReact.timeDateFormats.time);
    }
    return endValue;
  },
  getAverageTime: function(calendars, returnString = false) {
    if (Object.keys(calendars).length === 0) return 20;
    const averagesTime = pluck(calendars, "averageTime");
    const finalAverageTime = this.gcdArrays(averagesTime);
    if (
      finalAverageTime &&
      finalAverageTime.toString().length === 1 &&
      returnString
    ) {
      return `0${finalAverageTime}`;
    }
    return finalAverageTime;
  },
  gcdArrays: function(averagesTime) {
    if (averagesTime.length < 1) {
      return undefined;
    }
    while (averagesTime.length > 1) {
      const a_b = averagesTime.splice(0, 2); // get the two first elem
      const local_gcd = this.gcd(a_b[0], a_b[1]); // find gcd
      averagesTime.splice(0, 0, local_gcd); // add it on array
    }
    return averagesTime[0];
  },
  gcd: function(a, b) {
    if (a <= 0) return b;

    while (b > 0) {
      if (a > b) a = a - b;
      else b = b - a;
    }

    return a;
  },
  sortCalendars: function(calendars) {
    return calendars.sort(function(cal1, cal2) {
      var priority1 = cal1.priority;
      var priority2 = cal2.priority;
      var lastName1 = cal1.lastName.toLowerCase();
      var lastName2 = cal2.lastName.toLowerCase();
      var firstName1 = cal1.firstName.toLowerCase();
      var firstName2 = cal2.firstName.toLowerCase();
      var id1 = cal1.id;
      var id2 = cal2.id;

      if (priority1 > priority2) return 1;
      else if (priority1 < priority2) return -1;
      else if (lastName1 > lastName2) return 1;
      else if (lastName1 < lastName2) return -1;
      else if (firstName1 > firstName2) return 1;
      else if (firstName1 < firstName2) return -1;
      else if (id1 < id2) return -1;
      else if (id1 > id2) return 1;
      else return -1;
    });
  },

  sortfilters: function(filters) {
    return filters.sort(function(filter1, filter2) {
      var priority1 = filter1.priority;
      var priority2 = filter2.priority;
      var id1 = filter1.id;
      var id2 = filter2.id;

      if (priority1 > priority2) return 1;
      else if (priority1 < priority2) return -1;
      else if (id1 < id2) return -1;
      else if (id1 > id2) return 1;
      else return -1;
    });
  }
};

export default CalendarUtils;

