import Languages from "shared/languages";
import CalendarUtilsReact from "shared/calendar_utils_react.js";
import Config from "shared/config";

var xss = require("xss");

var ProgendaUtils = {
  isColorBright: function(color) {
    var r = parseInt(color.substring(1, 3), 16);
    var g = parseInt(color.substring(3, 5), 16);
    var b = parseInt(color.substring(5, 7), 16);
    var brightness = r * 0.299 + g * 0.587 + b * 0.114;
    return brightness > 160;
  },
  isMobile: function() {
    return (
      "ontouchstart" in window ||
      (window.DocumentTouch && document instanceof DocumentTouch)
    );
  },
  /**
   * If `noNewLines` is TRUE, the br tag will be excluded from the white list
   * This was needed on the Campaign main content text area to verify if we have real text typed,
   * or there are only empty lines
   */
  filterXSS: function(text, noNewLines = false, allowLists = false, allowLinks = false) {
    const whiteList = { b: [], i: [], u: [] };
    if (!noNewLines) {
      whiteList.br = [];
    }
    if (allowLists) {
      whiteList.ul = [];
      whiteList.li = [];
    }
    if (allowLinks) {
      whiteList.a = ["href"];
    }
    return xss(text, {
      whiteList,
      stripIgnoreTag: true,
      stripIgnoreTagBody: ["script"]
    });
  },
  fromValueToMoment: function(input) {
    try {
      var mom = moment(typeof input === "number" ? input * 1000 : input);
      return mom;
    } catch (e) {
      return moment();
    }
  },
  dateFormat: function(when, format) {
    try {
      var momentJS = ProgendaUtils.fromValueToMoment(when);
      var dateJS = new Date(
        momentJS.year(),
        momentJS.month(),
        momentJS.date(),
        momentJS.hours(),
        momentJS.minutes(),
        momentJS.seconds(),
        momentJS.milliseconds()
      );
      return I18n.get().strftime(dateJS, I18n.get().t(format));
    } catch (e) {
      return "";
    }
  },
  momentWithoutTimezone: function(input) {
    return moment(
        moment.utc(input).format(CalendarUtilsReact.noTimezoneFormat)
    );
  },
  getFromURL: function(parameter) {
    return new URLSearchParams(window.location.search).get(parameter);
  },
  parseReadableDate: function(when) {
    return moment(when, I18n.get().t("moment_parsed_format"), true);
  },
  dateFormatWithMask: function(when) {
    return when
      ? ProgendaUtils.fromValueToMoment(when).format(
          I18n.get().t("moment_parsed_format")
        )
      : "";
  },
  setStorageItem: function(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {}
  },
  getStorageItem: function(key) {
    return localStorage.getItem(key);
  },
  generateChartParams: function(titles, data, useHelenaProDesign) {
    var nColor = 2;
    var backgroundColor = ["rgba(231, 231, 231, 0.6)", "rgba(217, 92, 92, 1)"];
    var borderColor = ["rgba(155, 155, 155, 0.7)", "rgba(217, 92, 92, 1)"];

    if (useHelenaProDesign) {
      backgroundColor[1] = "rgba(46, 192, 183, 1)";
      borderColor[1] = "rgba(46, 192, 183, 1)";
    }

    return titles.map(function(title, index) {
      var colorIndex = nColor - titles.length + index;
      return {
        label: title,
        fill: true,
        backgroundColor: backgroundColor[colorIndex],
        borderColor: borderColor[colorIndex],
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointBorderColor: borderColor[colorIndex],
        pointHoverBorderColor: borderColor[colorIndex],
        pointHitRadius: 20,
        lineTension: 0.2,
        borderWidth: 0,
        data: data[index]
      };
    });
  },
  isConflictingEvent: function(
    startMoment1,
    stopMoment1,
    startMoment2,
    stopMoment2
  ) {
    var startUnix1 = startMoment1.unix();
    var stopUnix1 = stopMoment1.unix();
    var startUnix2 = startMoment2.unix();
    var stopUnix2 = stopMoment2.unix();
    return (
      (startUnix2 >= startUnix1 && startUnix2 < stopUnix1) || // [start1________start2______stop1]
      (startUnix1 >= startUnix2 && startUnix1 < stopUnix2)
    ); // [start2________start1______stop2]
  },

  retrieveScheduleIds: function(objects) {
    return objects.map(function(object) {
      return object.schedule.id;
    });
  },

  getStripedCssEffect: function(color) {
    var darkenColor = ProgendaUtils.lightenDarkenColor(color, 20);
    return (
      "repeating-linear-gradient(45deg, " +
      color +
      ", " +
      color +
      " 10px, " +
      darkenColor +
      " 10px, " +
      darkenColor +
      " 20px)"
    );
  },

  lightenDarkenColor: function(col, amt) {
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  },

  indexCurrentTranslation(translations) {
    var locale = I18n.get().locale;
    var index = translations.findIndex(
      translation => translation.code == locale
    );
    return Math.max(index, 0);
  },

  computeTranslations(languages, translations, splitFirst) {
    var languages = languages === undefined ? Languages.get() : languages;
    var translations =
      translations === undefined
        ? languages.map(function(lan) {
            return { code: lan.code, translation: "" };
          })
        : translations.filter(function(translation) {
            return languages.find(function(language) {
              return language.code === translation.code;
            });
          });
    var splitFirst = splitFirst === undefined ? true : splitFirst;
    if (splitFirst) {
      var currentTranslation = translations.splice(
        this.indexCurrentTranslation(translations),
        1
      )[0];
    }
    return {
      translations: translations,
      currentTranslation: currentTranslation
    };
  },

  appendToHtmlName: function(name, element) {
    var index = name.lastIndexOf("]");
    return name.slice(0, index) + element + name.slice(index);
  },

  appendToHtmlId: function(name, element) {
    return name + element;
  },

  randomId: function() {
    return Math.random()
      .toString()
      .slice(2, -1);
  },

  /**
   * This method will transform the url search query string to an object
   */
  URLQueryToObject: () => {
    if (typeof window !== undefined) {
      const { search } = window.location;
      if (!search.length) {
        return null;
      }
      const queryObject = {};
      const queryString = search.slice(1);

      queryString.split("&").forEach(pair => {
        const [key, value] = pair.split("=");
        if (!queryObject.key) {
          try {
            queryObject[key] = decodeURIComponent(value);
          } catch (e) {
            queryObject[key] = value;
          }
        }
      });
      return queryObject;
    }
    return null;
  },

  /**
   * Next method reads the CSRF token from <head>
   */
  getCSRFToken: () => {
    const token = document.head.querySelector("[name='csrf-token']");
    if (!token) {
      return null;
    }
    return token.content;
  },

  /**
   * this just checks if the format of the email is something@something.something
   * we could improve it in future or use a package like https://www.npmjs.com/package/validator
   * TODO: Decide what to do with it?
   */
  validateEmailFormat: value => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  },

  getCookie: (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  },

  allowedDisplayContextualLaunch: (center) => {
    let useHelenaEmbeddable = Config.get().useHelenaEmbeddable;
    if (useHelenaEmbeddable) { // HP Calendar embed
      return ProgendaUtils.getCookie('context') !== "NO_EPD"
    }
    else { // Progenda
      return center.contextualLaunchUrl !== ""
    }
  }
};

String.prototype.pad10 = function() {
  if (this.length < 2) return "0" + this;
  else return this.toString();
};

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export default ProgendaUtils;
