var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { string } from "prop-types";
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal(props) {
        var _this = _super.call(this, props) || this;
        _this._modal = null;
        _this.show = function () {
            //@ts-ignore
            $(_this._modal).modal(_this.props.preventClick
                ? {
                    backdrop: "static",
                    keyboard: false
                }
                : {});
            var modalBackdrops = $(".modal-backdrop");
            if (modalBackdrops.length > 1) {
                $(_this._modal).insertAfter($(".modal-backdrop").eq(1));
            }
            else {
                $(".modal-backdrop").insertAfter($(_this._modal));
            }
        };
        _this.hide = function () {
            if ($(_this._modal).hasClass("in")) {
                //@ts-ignore
                $(_this._modal).modal("hide");
            }
        };
        _this.state = { visible: false };
        return _this;
    }
    Modal.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, onShow = _a.onShow, onShown = _a.onShown, onHide = _a.onHide, onHidden = _a.onHidden;
        $(this._modal).on("show.bs.modal", function () {
            _this.setState({ visible: true });
            if (onShow) {
                onShow();
            }
        });
        $(this._modal).on("hide.bs.modal", function () {
            _this.setState({ visible: false });
            if (onHide) {
                onHide();
            }
        });
        $(this._modal).on("shown.bs.modal", function () {
            if (onShown) {
                onShown();
            }
        });
        $(this._modal).on("hidden.bs.modal", function () {
            if ($('.modal:visible').length) {
                $('body').addClass('modal-open');
            }
            if (onHidden) {
                onHidden();
            }
        });
    };
    Modal.prototype.componentWillUnmount = function () {
        this.hide();
    };
    Modal.prototype.render = function () {
        var _this = this;
        var _a = this.props, htmlClass = _a.htmlClass, large = _a.large, className = _a.className, displayChild = _a.displayChild, children = _a.children;
        return (React.createElement("div", { className: "modal fade " + (htmlClass || ""), ref: function (c) { return (_this._modal = c); }, tabIndex: -1, role: "dialog", "aria-hidden": "true" },
            React.createElement("div", { className: "modal-dialog" + (large ? " modal-lg" : "") },
                React.createElement("div", { className: "modal-content " +
                        (this.state.visible ? "" : "hidden ") +
                        (className ? className : "") }, (this.state.visible || displayChild) && children))));
    };
    Modal.propTypes = {
        /** Custom class name for main modal element */
        htmlClass: string
    };
    return Modal;
}(React.Component));
export default Modal;
