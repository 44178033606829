/* Update : 2019-08-23 09:00 */

var Assets = {
    
    "eID-verso-de-new.png": "/assets/eID-verso-de-new-a91ddeea222180842b105dac20c00222ac5cfddea3073bbecb44c8c6e2f82455.png",
    
    "eID-verso-de-old.png": "/assets/eID-verso-de-old-793c0dd4c52895c15d9b21514ee14cf244e90a0399610a5cf2ab6451f9b38c28.png",
    
    "eID-verso-fr-new.png": "/assets/eID-verso-fr-new-c238023b754774611826b4edd612a7d459c97b446d10b756cf1a3abe4c5a2a0a.png",
    
    "eID-verso-fr-old.png": "/assets/eID-verso-fr-old-d0bf28a0fb7a4e3d8f0c545922e8b2c8529e380794cd17f32273dc0260f60ffa.png",
    
    "eID-verso-nl-new.png": "/assets/eID-verso-nl-new-e97595c4c272ff7815572ed65a569bbea0e3775d3c5cd53308232fa47333be0e.png",
    
    "eID-verso-nl-old.png": "/assets/eID-verso-nl-old-f632d971c21a228129c2f480ca70dd458b547006a0b591614e68fef1d952c890.png",
    
    "isi-front.png": "/assets/isi-front-a73602b982b3415166cf2852bcbf1275b511816021ba0474493c0fb0b6bd5b5d.png",
    
};

export default Assets;